<template>
  <b-overlay :show="loading">
    <section v-if="Object.keys(pricing).length" id="pricing-plan">
      <!-- title text and switch button -->
      <div class="text-center" v-if="getSubscribePlansInfo">
        <h1 class="mt-2">{{ getSubscribePlansInfo.message }}</h1>
        <p class="mb-2 pb-75">
          Lorem ipsum dolor, sit amet consectetur adipisicing elit. Magnam
          eveniet fuga eligendi, illo temporibus adipisci veniam dolorum quam
          quasi quis, debitis assumenda alias cumque? Tempora deleniti labore id
          voluptatum facilis!

          <!-- {{ getSubscribePlansInfo }} -->
        </p>
        <div
          class="d-flex align-items-center justify-content-center mb-2 pb-50"
        >
          <h6 class="mr-1 mb-0">Monthly</h6>
          <b-form-checkbox
            id="priceSwitch"
            v-model="status"
            name="price-switch"
            value="annually"
            unchecked-value="monthly"
            switch
            @input="tooglePlan"
          />
          <h6 class="ml-50 mb-0">Annually</h6>
        </div>
      </div>
      <!--/ title text and switch button -->

      <!-- pricing plan cards -->
      <b-row class="pricing-card">
        <b-col
          sm="10"
          md="12"
          lg="10"
          class="mx-auto"
          v-if="getSubscribePlansInfo"
        >
          <b-row>
            <b-col
              md="4"
              v-for="(planItem, index) in getSubscribePlansInfo.data"
              :key="index"
            >
              <b-card align="center">
                <!-- img -->
                <div
                  class="d-flex flex-row justify-content-center align-items-end mb-1"
                  style="height:125px"
                >
                  <b-img
                    fluid
                    :src="planIcon[planItem.name]"
                    :alt="planIcon[planItem.name]"
                  />
                </div>
                <!--/ img -->
                <h3>{{ planItem.name }}</h3>
                <b-card-text>
                  {{ planItem.description[0] }}
                </b-card-text>

                <div
                  class="annual-plan"
                  v-for="(priceItem, index) in planItem.price_list"
                  :key="index"
                >
                  <div v-if="priceItem.plan_category === monthlyPlanShow">
                    <div class="plan-price mt-2">
                      <sup class="font-medium-1 font-weight-bold text-primary"
                        >$</sup
                      >
                      <span
                        class="
                          pricing-basic-value
                          font-weight-bolder
                          text-primary
                        "
                        >{{ priceItem.amount }}</span
                      >
                      <sub
                        class="
                          pricing-duration
                          text-body
                          font-medium-1 font-weight-bold
                        "
                        >/ {{ priceItem.plan_category.toLowerCase() }}</sub
                      >
                    </div>
                  </div>
                </div>

                <!-- plan benefit -->
                <b-list-group class="list-group-circle text-left">
                  <b-list-group-item
                    v-for="(decItem, index) in planItem.description"
                    :key="index"
                  >
                    {{ decItem }}
                  </b-list-group-item>
                </b-list-group>
                <!--/ plan benefit -->

                <!-- buttons -->

                
                <b-button
                  v-ripple.400="'rgba(40, 199, 111, 0.15)'"
                  block
                  class="mt-2"
                  :variant="
                  (planItem.name==='Basic'? 'outline-warning':'')||
                  (planItem.name==='Advanced'? 'primary':'')||
                   (planItem.name==='Demo'? 'outline-success':'')"
                  @click="subscribeToPlan(planItem)"
                >
                  <b-spinner v-if="loading" small />
                  Subscribe Now!
                </b-button>
              </b-card>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
      <!--/ pricing plan cards -->
    </section>
  </b-overlay>
</template>

<script>
import {
  BFormCheckbox,
  BRow,
  BCol,
  BCard,
  BImg,
  BCardText,
  BListGroup,
  BListGroupItem,
  BButton,
  BBadge,
  BSpinner,
  BOverlay,
} from 'bootstrap-vue';
import Ripple from 'vue-ripple-directive';
/* eslint-disable global-require */
import store from '@/store/index';
import { mapGetters } from 'vuex';
export default {
  components: {
    BFormCheckbox,
    BButton,
    BCardText,
    BListGroup,
    BListGroupItem,
    BRow,
    BCol,
    BCard,
    BBadge,
    BImg,
    BSpinner,
    BOverlay,
  },
  directives: {
    Ripple,
  },

  computed: {
    ...mapGetters({
      getSubscribePlansInfo: 'app-dashboard/getSubscribePlans',
      getSubscriptionInfo: 'app-dashboard/getSubscriptionInfo',
    }),
    planList() {
      return true;
      // if(this.monthlyPlanShow)
      // return this.getSubscribePlansInfo.data.filter(info =)
    },
  },
  data() {
    return {
      status: 'monthly',
      monthlyPlanShow: 'Monthly',
      planIcon: {
        Basic: require('@/assets/images/illustration/Pot2.svg'),
        Advanced: require('@/assets/images/illustration/Pot3.svg'),
        Demo: require('@/assets/images/illustration/Pot1.svg'),
      },

      pricing: {
        qandA: [
          {
            question: 'Does my subscription automatically renew?',
            ans:
              'Pastry pudding cookie toffee bonbon jujubes jujubes powder topping. Jelly beans gummi bears sweet roll bonbon muffin liquorice. Wafer lollipop sesame snaps. Brownie macaroon cookie muffin cupcake candy caramels tiramisu. Oat cake chocolate cake sweet jelly-o brownie biscuit marzipan. Jujubes donut marzipan chocolate bar. Jujubes sugar plum jelly beans tiramisu icing cheesecake.',
          },
          {
            question:
              'Can I store the item on an intranet so everyone has access?',
            ans:
              'Tiramisu marshmallow dessert halvah bonbon cake gingerbread. Jelly beans chocolate pie powder. Dessert pudding chocolate cake bonbon bear claw cotton candy cheesecake. Biscuit fruitcake macaroon carrot cake. Chocolate cake bear claw muffin chupa chups pudding.',
          },
          {
            question: 'Am I allowed to modify the item that I purchased?',
            ans:
              'Tart gummies dragée lollipop fruitcake pastry oat cake. Cookie jelly jelly macaroon icing jelly beans soufflé cake sweet. Macaroon sesame snaps cheesecake tart cake sugar plum. Dessert jelly-o sweet muffin chocolate candy pie tootsie roll marzipan. Carrot cake marshmallow pastry. Bonbon biscuit pastry topping toffee dessert gummies. Topping apple pie pie croissant cotton candy dessert tiramisu.',
          },
        ],
      },
      loading: false,
      isLoading: false,
    };
  },

  methods: {
    tooglePlan() {
      if (this.status === 'monthly') {
        this.monthlyPlanShow = 'Monthly';
      } else {
        this.monthlyPlanShow = 'Yearly';
      }
    },
    subscribeToPlan(planItem) {
      this.loading = true;
      store
        .dispatch('app-customer/extendPlan', {
          plan_id: planItem.id,
          plan_price_id: 2,
        })
        .then((response) => {
          this.loading = false;
          if (response.data.result.code === 200) {
            window.open(response.data.result.data.url, '_self');
          } else {
            this.$bvToast.toast('Something went wrong', {
              title: 'Failed',
              variant: 'danger',
              solid: true,
            });
          }
        });
    },
  },
};
/* eslint-disable global-require */
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-pricing.scss';
</style>
