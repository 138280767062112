<template>
  <!-- pricing faq -->
  <div class="pricing-faq">
    <h3 class="text-center">
      FAQ's
    </h3>
    <p class="text-center">
      Let us help answer the most common questions.
    </p>
    <b-row class="py-2">
      <b-col lg="10" offset-lg="2" class="mx-auto">
        <app-collapse accordion type="margin">
          <app-collapse-item
            v-for="(data, index) in FaqPlan"
            :key="index"
            :title="data.question"
          >
            {{ data.ans }}
          </app-collapse-item>
        </app-collapse>
      </b-col>
    </b-row>
  </div>
  <!--/ pricing faq -->
</template>

<script>
import {
  BFormCheckbox,
  BRow,
  BCol,
  BCard,
  BImg,
  BCardText,
  BListGroup,
  BListGroupItem,
  BButton,
  BBadge,
} from 'bootstrap-vue';
import Ripple from 'vue-ripple-directive';
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue';
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue';
/* eslint-disable global-require */
export default {
  components: {
    BFormCheckbox,
    BButton,
    BCardText,
    BListGroup,
    BListGroupItem,
    BRow,
    BCol,
    BCard,
    BBadge,
    BImg,
    AppCollapseItem,
    AppCollapse,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      FaqPlan: [
        {
          question: 'Does my subscription automatically renew?',
          ans:
            'Lorem ipsum dolor sit amet consectetur adipisicing elit. Eveniet dolores laboriosam inventore aperiam, provident ad omnis magni sapiente porro ipsa nulla, nobis laborum, facilis placeat dolor. Quas esse eligendi iusto?',
        },
        {
          question:
            'Can I store the item on an intranet so everyone has access?',
          ans:
            'Lorem ipsum dolor sit amet consectetur adipisicing elit. Eveniet dolores laboriosam inventore aperiam, provident ad omnis magni sapiente porro ipsa nulla, nobis laborum, facilis placeat dolor. Quas esse eligendi iusto?',
        },
        {
          question: 'Am I allowed to modify the item that I purchased?',
          ans:
            'Lorem ipsum dolor sit amet consectetur adipisicing elit. Eveniet dolores laboriosam inventore aperiam, provident ad omnis magni sapiente porro ipsa nulla, nobis laborum, facilis placeat dolor. Quas esse eligendi iusto?',
        },
      ],
    };
  },

  methods: {},
};
/* eslint-disable global-require */
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-pricing.scss';
</style>
