<template>
<b-overlay
    :show="isLoading"
  >
  <div>
    <pricing-plan />
    <trial-plan />
    <faq-plan />
  </div>
</b-overlay>
</template>

<script>
import { BOverlay } from 'bootstrap-vue'
import PricingPlan from '@/components/subscription/PricingPlan.vue';
import TrialPlan from '@/components/subscription/TrialPlan.vue';
import FaqPlan from '@/components/subscription/FaqPlan.vue';
import store from '@/store/index';
export default {
  components: {
    BOverlay,
    PricingPlan,
    TrialPlan,
    FaqPlan,
  },
  data(){
    return{
      isLoading:false,
    }
  },
  created() {
    this.fetchSubscribePlanPrice();
  },

  methods: {
    fetchSubscribePlanPrice() {
      this.isLoading = true;
      store.dispatch('app-dashboard/fetchSubscribePlanPrice').then((response) => {
        if(response.data.data) this.isLoading = false;
       
      });
      store.dispatch('app-dashboard/fetchSubscribeDashboard').then(() => {});
    },
  },
};
</script>

<style></style>
