<template>
  <!-- pricing free trial -->
  <div class="pricing-free-trial">
    <b-row>
      <b-col lg="10" offset-lg="3" class="mx-auto">
        <div class="pricing-trial-content d-flex justify-content-between">
          <div class="text-center text-md-left mt-3">
            <h3 class="text-primary">
              Still not convinced? Start with a 14-day FREE trial!
            </h3>
            <h5>
              You will get full access to with all the features for 14 days.
            </h5>
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="danger"
              class="mt-2 mt-lg-3"
            >
              Start 14-day FREE trial
            </b-button>
          </div>

          <!-- images -->
          <b-img
            fluid
            :src="
              require('@/assets/images/illustration/pricing-Illustration.svg')
            "
            class="pricing-trial-img"
            alt="svg img"
          />
          <!--/ images -->
        </div>
      </b-col>
    </b-row>
  </div>
  <!--/ pricing free trial -->
</template>

<script>
import {
  BFormCheckbox,
  BRow,
  BCol,
  BCard,
  BImg,
  BCardText,
  BListGroup,
  BListGroupItem,
  BButton,
  BBadge,
} from 'bootstrap-vue';
import Ripple from 'vue-ripple-directive';
/* eslint-disable global-require */
export default {
  components: {
    BFormCheckbox,
    BButton,
    BCardText,
    BListGroup,
    BListGroupItem,
    BRow,
    BCol,
    BCard,
    BBadge,
    BImg,
  },
  directives: {
    Ripple,
  },
  data() {
    return {};
  },

  methods: {},
};
/* eslint-disable global-require */
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-pricing.scss';
</style>
